module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["GTM-KDNCTHX"],"gtagConfig":{"optimize_id":"OPT_CONTAINER_ID","anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"denyUrls":[{},{},{}],"dsn":"https://0952ecdc3d9a4a13a06f8f493a9d71e4@o1115718.ingest.sentry.io/6148101","environment":"production","release":"yhh-website@","tracesSampleRate":0.2},
    }]
